import { twMerge } from "tailwind-merge"
import CircleProgressBar from "../ProgressBar"
import { LockWhiteIcon } from "../../icons"

export const ScoreBlockElement = ({ bgColor, mainColor, secondColor, textColor, score, title, smallMode, blur = false, lock = false }) => {
    return (
        <div className={twMerge('w-[140px] h-[140px] rounded-[16px] flex justify-center', smallMode ? 'w-[116px] h-[116px]' : '')} style={{ backgroundColor: bgColor }} key={title}>
            <div className="flex self-center">
                <div className="w-full flex flex-col">
                    <div className={twMerge("text-[16px] w-full leading-[19px] text-center font-[600]", blur ? 'blur-[8px]' : '', smallMode ? 'text-[13px] leading-[15px]' : '')} style={{ color: textColor }}>
                        {title}
                    </div>
                    <div className={twMerge("w-[80px] h-[80px] mt-[8px] relative", smallMode ? 'w-[66px] h-[66px] mt-[6px]' : '')}>
                        <div className={twMerge("relative flex justify-center self-center items-center w-[80px] h-[80px]", (blur || lock) ? 'blur-[8px]' : '', smallMode ? 'w-[66px] h-[66px]' : '')}>
                            <CircleProgressBar smallMode={smallMode} className="absolute z-[1] top-0 left-0" score={score} mainColor={mainColor} secondColor={secondColor} />
                            <div className={twMerge("text-[30px] font-bold", smallMode ? 'text-[24px]' : '')} style={{ color: textColor }}>
                                {score}
                            </div>
                        </div>
                        {lock && <LockWhiteIcon className=" z-[2] absolute w-[16px] h-[20[x]  top-[25px] left-[27px]" />}
                    </div>
                </div>
            </div>
        </div >
    )

}