import { useTranslation } from "react-i18next"
import { renderWithLineBreaks } from "../../utils"
import Modal from "../Modal"
import ScoreDisplay from "../BlockScore";
import { FeaturesBlock } from "../Result/Features";

export const ModalListPaywall = ({ opened, onClose, mode, items, onPay }) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={opened} onClose={onClose} contentClassName="min-h-[517px] relative flex flex-col items-center">
            <div className="text-[26px] mt-[40px] font-[600] text-[#232948] leading-[normal] text-center">
                {mode === 'features' ?
                    renderWithLineBreaks(t('PAYWALL_FEATURES_TITLE'))
                    :
                    renderWithLineBreaks(t('PAYWALL_RATINGS_TITLE'))
                }
            </div>
            <div className="relative">
                {mode === 'features' ?
                    <FeaturesBlock features={items} blur={true} showButton={false} smallMode={true} /> :
                    <ScoreDisplay ratings={items} blur={true} smallMode={true} />
                }
            </div>
            <div className="mt-[10px] flex">
                <button onClick={onPay} className='font-bold ml-[10px] w-[240px] h-[64px] flex text-xl text-[white] justify-center items-center bg-[#0076ff] rounded-[16px]'>
                    {renderWithLineBreaks(t('PAYWALL_BUTTON'))}
                </button>
            </div>
            <div className="mt-[13px] text-center text-[14px] opacity-80 text-[#010101] leading-[1.29]">
                {renderWithLineBreaks(t('PAYWALL_NOTICE'))}
            </div>
        </Modal>
    )
}