import { useTranslation } from "react-i18next"
import { renderWithLineBreaks } from "../../utils"
import { ButtonElem } from "./ButtonElem";
import heart from '../../images/shapes/heart@3x.png';
import diamond from '../../images/shapes/diamond@3x.png';
import oblong from '../../images/shapes/oblong@3x.png';
import oval from '../../images/shapes/oval@3x.png';
import pear from '../../images/shapes/pear@3x.png';
import rectangle from '../../images/shapes/rectangle@3x.png';
import round from '../../images/shapes/round@3x.png';
import square from '../../images/shapes/square@3x.png';
import triangle from '../../images/shapes/triangle@3x.png';
import { useCallback } from "react";
import { makeRequest } from "../../utils/request";
import { twMerge } from "tailwind-merge";

const getImageSrc = (shapeType) => {
    switch (shapeType) {
        case 'heart':
            return heart;
        case 'diamond':
            return diamond;
        case 'oblong':
            return oblong;
        case 'oval':
            return oval;
        case 'pear':
            return pear;
        case 'rectangle':
            return rectangle;
        case 'round':
            return round;
        case 'square':
            return square;
        case 'triangle':
            return triangle;
        default:
            return heart;
    }
}

export const FeaturesBlock = ({ features, faceShape, imageSrc, onClickFullProfile, showButton = true, smallMode = false, blur = false, showButtonText = true, className }) => {
    const { t } = useTranslation();
    const hasFeatures = !!features.length > 0;

    const onClickButton = useCallback(() => {
        makeRequest('/user/update', 'post', {
            tap: 'profile'
        });

        onClickFullProfile();
    }, [onClickFullProfile])

    if (!hasFeatures && !faceShape) {
        return null;
    }

    return (
        <>
            {showButton && <div className="max-w-[260px] text-[#242a46] mt-[40px] text-base leading-[normal] font-bold flex justify-center text-center tracking-[normal]">
                {renderWithLineBreaks(t('SCORE_FEATURES_TITLE'))}
            </div>}

            {faceShape ?
                <div className="mt-[14px] flex justify-center items-center relative w-[290px] h-[306px]">
                    <img alt="" src={getImageSrc(faceShape.file_name)} className="w-[290px] h-[306px] absolute top-0 left-0" />
                    <img alt="" src={imageSrc} className="rounded-[50%] w-[98px] h-[98px] relative z-[10] mt-[18px] border-[1px] border-solid border-[#fff]" />
                </div>
                :
                <div className={twMerge("mt-[20px] w-full flex flex-col gap-[10px] max-w-[290px] cursor-pointer", className)} onClick={showButton ? onClickButton : () => { }}>
                    {features.map(({ caption, value }) => (
                        <div key={caption} className={twMerge("w-full max-w-[290px] px-[30px] text-[#f2f2f2] h-[65px] text-[18px] font-[600] rounded-[16px] flex justify-between items-center bg-[#242a46]", smallMode ? 'h-[53px]' : '')}>
                            <div className={twMerge("text-[16px] font-[600]", blur ? 'blur-[8px]' : '', smallMode ? 'text-[13px]' : '')}>
                                {caption}
                            </div>

                            <div className={twMerge("text-[16px] font-bold text-right", blur ? 'blur-[8px]' : '', smallMode ? 'text-[13px] font-[900]' : '')}>
                                {value}
                            </div>
                        </div>
                    ))}

                </div>
            }

            {showButton && <ButtonElem
                onClick={onClickButton}
                text={renderWithLineBreaks(t('SCORE_FEATURES_BUTTON'))}
                helpText={showButtonText ? renderWithLineBreaks(t('SCORE_FEATURES_NOTICE')) : undefined}
            />}
        </>
    )
}