import { Link } from "react-router-dom";
import { makeRequest } from "./request";
import React from "react";
import axios from "axios";

export const getColorByType = (type) => {
    switch (type) {
        case 'HOT':
            return { bg: '#ff5656', text: '#ffffff' };
        case 'STUNNING':
            return { bg: '#ff8888', text: '#000000' };
        case 'ATTRACTIVE':
            return { bg: '#fee114', text: '#000000' };
        case 'CUTE':
            return { bg: '#d1d80f', text: '#000000' };
        case 'AVERAGE':
            return { bg: '#84bd32', text: '#000000' };
        case 'UNIQUE':
            return { bg: '#30ad43', text: '#ffffff' };
        case 'PROCESSING':
            return { bg: '#fee114', text: '#242a46' };
        default:
            return { bg: '#30ad43', text: '#ffffff' };
    }
}


export const getNewColorByType = (type) => {
    switch (type) {
        case 'HOT':
            return { main: '#ff4500', second: '#392c3f' };
        case 'STUNNING':
            return { main: '#ff6347', second: '#392f45' };
        case 'ATTRACTIVE':
            return { main: '#ff7f50', second: '#393246' };
        case 'CUTE':
            return { main: '#ffa500', second: '#39363f' };
        case 'AVERAGE':
            return { main: '#ffd700', second: '#393b3f' };
        case 'UNIQUE':
            return { main: '#30ad43', second: '#243645' };
        default:
            return { main: '#30ad43', second: '#243645' };
    }
}

export const getTypeByScore = (score) => {
    if (score >= 90) {
        return 'HOT'
    }
    if (score >= 80) {
        return 'STUNNING'
    }
    if (score >= 70) {
        return 'ATTRACTIVE'
    }
    if (score >= 60) {
        return 'CUTE'
    }
    if (score >= 30) {
        return 'AVERAGE'
    }
    return "UNIQUE"
}

export const loadResultScore = async (photoId, paywallMethod, isExpressMode = false) => {

    let loadedResult = false
    if (!photoId) {
        return;
    }
    while (!loadedResult) {
        const response = await makeRequest(isExpressMode ? '/score/express/status' : '/score/advanced/status', 'post', {
            score_id: photoId,
            paywall: paywallMethod === 'none' ? undefined : paywallMethod,
        }, {}, false);
        if (response.data.status && response.data.result.status === 1) {
            loadedResult = true;
        } else if (response.data.status === false && response.data.message) {
            return response;
        } else {
            await waitFor(1000);
        }
    }

    const respResult = await makeRequest(isExpressMode ? `/score/express/result` : '/score/advanced/result', 'post', {
        score_id: photoId,
        paywall: paywallMethod === 'none' ? undefined : paywallMethod,
    }, {}, false);

    return respResult

}

export const loadPayment = async (scoreId,) => {
    let loadedResult = false
    if (!scoreId) {
        return;
    }
    while (!loadedResult) {
        const response = await makeRequest('/payment/status', 'post', {
            score_id: scoreId,
        }, {}, false);

        if (response.data.status && response.data.result.status === 1) {
            loadedResult = true;
            return 1;
        } else if (response.data.status === false || response.data.result.status === 2) {
            return 2;
        } else {
            await waitFor(1000);
        }
    }

    return 2;
}



export const loadCharacter = async (photoId, onChangeProgress, onTimeoutExtended, timeoutNumber) => {
    let loadedResult = false;

    setTimeout(() => {
        if (!loadedResult) {
            loadedResult = true;
            onTimeoutExtended();
        }
    }, timeoutNumber * 1000)

    while (!loadedResult) {
        const response = await makeRequest(`/image/status`, 'post', {
            score_id: photoId
        }, {}, false);

        if (response.data.status && response.data.result.url) {
            return response;
        } else {
            onChangeProgress(response.data.result.progress);
            await waitFor(1000);
        }
    }
}

export const waitFor = (time) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve()
        }, [time])
    })
}


export const isTelegram = () => {
    const keys = Object.keys(window.Telegram?.WebView?.initParams || {});
    return keys.length > 0;
}

export function isAndroid() {
    return /Android/i.test(navigator.userAgent);
}

export const renderWithLineBreaks = (text = '') => {
    return text.split('\n').map((part, index) => (
        <React.Fragment key={index}>
            {renderBoldText(part)}
            {index < text.split('\n').length - 1 && <br />}
        </React.Fragment>
    ));
};


const renderBoldText = (text) => {
    const parts = text.split(/(\*\*.*?\*\*)/); // Split text at bold markers
    return parts.map((part, index) => {
        if (part.startsWith('**') && part.endsWith('**')) {
            // Remove asterisks and make bold
            return <strong key={index}>{part.slice(2, -2)}</strong>;
        }
        return renderTextWithComponents(part); // Return other parts as normal text
    });
};


const renderTextWithComponents = (text) => {
    // Split the text based on the placeholders {terms} and {privacy}
    const parts = text.split(/(\{terms\}|\{policy\})/);

    // Map over the parts and replace placeholders with components
    return parts.map((part, index) => {
        if (part === '{terms}') {
            return <Link className="underline" to="/ter" key={index}>Terms of Use</Link>;
        }
        if (part === '{policy}') {
            return < Link className="underline" to="/pri" key={index}> Privacy Policy</Link >;
        }
        return part; // return plain text for other parts
    });
};


export const imageUrlToBase64 = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
        };
        reader.onerror = reject;
    });
};

export const getPaymentRequest = async (userId, itemId, projectId, orderId) => {

    const res = await axios.post('https://sklpay-583094120085.us-central1.run.app/api/v1/payments/create', {
        project_id: projectId,
        user_id: userId,
        checkout_item_id: itemId,
        order_id: orderId,
        webhook_url: `${process.env.REACT_APP_API_LINK}/payment/webhook`,
        domain: window.location.origin,
    });

    return res;
}

export const getPaymentElemId = async (checkoutID) => {

    const res = await axios.get(`https://sklpay-583094120085.us-central1.run.app/api/v1/payments/checkout?checkout_id=${checkoutID}`,);

    if (res.data) {
        return { resId: res.data.order_id, email: res.data.customer_email };
    }
}
