import { LoadingIcon } from "../icons";
import "../styles/Animation.css";
import { renderWithLineBreaks } from '../utils';


export const Loader = ({ progress, headerText, text }) => {

    return (
        <div className={`flex flex-col items-center justify-center ${progress && 'mt-[36px]'} h-[95vh] w-[98vw] absolute top-0 left-0`}>
            <LoadingIcon className="loader absolute" />
            {progress && <>
                <div className='h-[72px] mt-[140px] text-[18px] tracking-[normal] leading-[1.33] font-header font-bold text-[#232948] text-center '>
                    Process could take
                    <br />
                    a few minutes
                    <br />
                    {progress}
                </div>
            </>
            }
            {
                headerText && <>
                    <div className='text-[38px] mt-[210px] font-[500] text-[#232948] leading-[1.05] text-center'>
                        {renderWithLineBreaks(headerText)}
                    </div>

                    <div className="mt-[18px] text-[#010101] text-center opacity-80 leading-[1.29] text-[22px]">
                        {renderWithLineBreaks(text || '')}
                    </div>
                </>
            }
        </div>
    )
}