import React from 'react';
import { ScoreBlockElement } from './ScoreBlockElement';
import { getNewColorByType, getTypeByScore } from '../../utils';
import { twMerge } from 'tailwind-merge';

const ScoreDisplay = ({ ratings, smallMode = false, onClick, blur = false, className }) => {

    const renderScoreBlock = (title, score, lock) => {
        const type = getTypeByScore(score);
        const color = getNewColorByType(type);
        return (
            <ScoreBlockElement blur={blur} lock={lock} smallMode={smallMode} key={title} score={score} title={title} bgColor={'#242a46'} mainColor={color.main} secondColor={color.second} textColor={'#f2f2f2'} />
        )
    };

    return (
        <div onClick={smallMode ? () => { } : onClick} className={twMerge("w-[290px] flex flex-wrap gap-[10px] mt-[30px] cursor-pointer", smallMode ? 'w-[241px] gap-[9px]' : '', className)}>
            {
                ratings.map(({ caption, score, lock }) => renderScoreBlock(caption, score, lock))
            }
        </div>
    );
};

export default ScoreDisplay;